.color-bar {
  width: 30px;
  height: 100%;
  &-root {
    height: 100%;
    display: flex;
  }
  &-text {
    height: 100%;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;
    &-item{
      color: #fff;
      text-shadow: 0px 0px 2px #000000;
    }
  }
}
